<!-- 简历详情页面 -->
<template>
  <div class="box">
    <div class="wrap" v-loading="loading">
      <div class="get-btn-wrap">
        <div class="get-btn">
          <el-button
            class="btn"
            v-if="data.is_download === 1"
            style="backgroundcolor: #ccc !important"
            >已获取简历</el-button
          >
          <el-button class="btn" @click="onFuzhi(data)"  v-if="data.is_download === 1">复制微信号</el-button>

          <el-button class="btn" @click="down" v-else>获取简历</el-button>
         

        </div>
      </div>

      <!-- 基本信息 -->
      <div style="overflow: hidden">
        <div class="img-wrap">
          <img
            src="./../../assets/images/test/boy.png"
            alt
            v-if="data.basic_gender === '男'"
          />
          <img src="./../../assets/images/test/girl.png" alt v-else />
        </div>

        <div class="all-base">
          <div class="name">{{ data.basic_name }}</div>
          <div class="base">
            <span>{{ data.basic_gender }}</span>
            <i></i>
            <span>{{ data.basic_age }}岁</span>
            <i></i>
            <span v-if="data.resume_education_experience_list">{{
              data.resume_education_experience_list[0].education_background
            }}</span>
            <i></i>
            <span>{{ data.basic_exp_year }}年</span>
          </div>
          <!-- <div class="tag">
            <span>沟通力强</span>
            <span>执行力强</span>

            <span>学习力强</span>

            <span>诚信正直</span>
          </div>-->
          <div class="expect">
            <!-- <p>
              求职状态：
              <span>求职中</span>
            </p>-->
            <p>
              期望职位：
               <!--
              <span style="margin-right: 7px">{{
                 data.expect_position_name_list.join(" , ")
              }}</span>
             
            -->
             
              <span
                v-for="(item, index) in data.expect_position_name_list"
                :key="index"
                style="margin-right:7px"
                >{{ item .join(" , ")}}</span>
                
            </p>
            <p>
              期望地点：
              <span
                v-for="(item, index) in data.expect_position_place_list"
                :key="index"
                style="margin-right: 7px"
                >{{ item }}</span
              >
            </p>
            <p
              v-if="
                data.resume_expect_position_list &&
                data.resume_expect_position_list.length > 0
              "
            >
              期望薪资：
              <span
                >{{ data.resume_expect_position_list[0].salary_min }}-{{
                  data.resume_expect_position_list[0].salary_max
                }}元</span
              >
            </p>

            <p style="color: #ff001b" v-if="data.state_index > 0">
              求职状态：{{ data.state_str }}
            </p>
          </div>
        </div>
      </div>

      <!-- 工作经历 -->
      <div
        class="work"
        v-if="
          data.resume_work_experience_list &&
          data.resume_work_experience_list.length > 0
        "
      >
        <div v-if="data.resume_work_experience_list[0].position_name != ''">
          <div class="title">工作经历</div>
          <div
            v-for="(item, index) in data.resume_work_experience_list"
            :key="index"
          >
            <p class="company-name">{{ item.company_name }}</p>
            <p v-if="item.end_month != 0">
              工作时间：
              <span
                >{{ item.start_year }}年{{ item.start_month }}月-{{
                  item.end_year
                }}年{{ item.end_month }}月</span
              >
            </p>
            <p v-if="item.upper != 0">
              薪资水平：
              <span>{{ item.floor }}-{{ item.upper }}</span>
            </p>
            <p v-if="item.position_name != ''">
              在职职位：
              <span>{{ item.position_name }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 教育经历 -->
      <div
        class="edu"
        v-if="
          data.resume_education_experience_list &&
          data.resume_education_experience_list.length > 0
        "
      >
        <div v-if="data.resume_education_experience_list[0].school != ''">
          <div class="title">教育经历</div>
          <p
            v-for="(item, index) in data.resume_education_experience_list"
            :key="index"
          >
            <span v-if="item.school != ''">{{ item.school }}</span>
            <span v-if="item.end_month != 0">
              <i></i>
              {{ item.start_year }}年{{ item.start_month }}月-{{
                item.end_year
              }}年{{ item.end_month }}月
            </span>
            <span v-if="item.major != ''">
              <i></i>
              {{ item.major }}
            </span>
          </p>
        </div>
      </div>
      <!-- 自我评价 -->
      <div class="self" v-if="!loading">
        <div
          v-if="data.basic_self_evaluation && data.basic_self_evaluation != ''"
        >
          <div class="title">自我评价</div>
          <div class="self-word">{{ data.basic_self_evaluation }}</div>
        </div>
      </div>
    </div>


    <!--弹出支付 开始 -->
    <el-dialog
      title="在线充值"
      :visible.sync="centerDialogVisible"
      width="500px"
      center
    >
      <div class="chongzhiC">
        <div class="payConent">
          <div>
            <div class="payHeader">
              <div>充值惊喜价 <span>￥ 300</span></div>
              <div>
                <ul>
                  <li>1.可下载100份简历</li>
                  <li>2.不限量简历线索获取</li>
                  <li>3.免费使用社交裂变及短视频招聘广告发布</li>
                  <li>4.免费使用招聘流程管理与数据统计功能</li>
                </ul>
              </div>
            </div>

            <div class="fangshi">
              <div>支付方式：</div>
              <div>
                <span class="iconfont iconweixinzhifu"></span>
                微信支付
              </div>
            </div>
            <div>
              &nbsp;&nbsp; 实付：<span class="netPay">300</span>
              元
            </div>
          </div>
        </div>

        <div>
          <div>
            <div style="float: left">支付二维码：</div>
            <div class="aa" v-if="code_url != ''">
              <vue-qr :text="code_url" :size="250" :logoScale="0.3"> </vue-qr>

              <div v-if="is_overdue == true" class="is_payImgBg">
                二维码失效
              </div>
            </div>
          </div>

          <div class="nowPay" @click="qrcode_pay">生成支付二维码</div>
        </div>
      </div>
    </el-dialog>
    <!--弹出支付 结束 -->
 
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState, mapActions } from "vuex";
import { getResumeDetails, downResume, qrcode_pay, 
  get_zhifu_state, } from "@/api/resume";
import VueQr from "vue-qr";
  
export default {
  //import引入的组件需要注入到对象中才能使用
 
  components: {   VueQr },
  data() {
    //这里存放数据
    return {
      id: "",
      data: {},

      loading: true,
      //获取简历
      resumeGet: false,
      //标记
      signShow: false,


       uid: "", //订单编号
      centerDialogVisible: false, //是否弹出支付页面
      integral: 0, //当前用户实时积分
      code_url: "", //支付二维码路径或者

      is_overdue: false, //二维码是否过期 true 是过期，false 未过期
      timer: null, //定时器
      timer2: null,
      num: 0, //检测120秒

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {

    //弹出二维码支付
    qrcode_pay() {
      qrcode_pay({}).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.code_url = res.data.code_url;
          this.uid = res.data.uid;
          this.get_is_overdue();
          this.get_is_zhifu() ;
        }
      });
    },

    //判断是否支付成功,定时监测
    get_is_zhifu() {
      // 实现轮询
      this.timer2 = window.setInterval(() => {
        setTimeout(this.get_zhifu_state(), 0);
      }, 1000);
    },

    //判断是否支付成功
    get_zhifu_state() {
      let that = this;
      get_zhifu_state({ uid: this.uid }).then((res) => {
        console.log(res);
        if (res.code === 0) {
          that.$message({ message: "支付成功", type: "success" });
          clearInterval(this.timer2);
          this.timer2 = null;

         //支付完成后获取简历
          let data = {
            resume_id: this.id,
          };
          downResume(data).then((res) => {
            if (res.code === 0) {
              this.getData();
              this.getResumeIsDown();
              this.$message({
                type: "success",
                message: res.msg,
              });
            } 
           else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });

          //刷新当前页面
          // window.setInterval(() => {
          //   setTimeout(function () {
          //     that.$router.go(0);
          //   }, 0);
          // }, 2000);
        }
      });
    },

    //判断二维码是否过期
    get_is_overdue() {
      this.num = 0;
      this.is_overdue = false;

      // 实现轮询
      this.timer = window.setInterval(() => {
        setTimeout(this.getNum(), 0);
      }, 1000);
    },

    //定制二维码过期检测
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    // 请求是否有新消息
    getNum() {
      this.num++;
      console.log("请求" + this.num + "次");
      if (this.num == 120) {
        this.is_overdue = true;
        this.stop();
      }
    },

    // 复制手机号到剪切板
    onFuzhi(item) {
      //获取数据跳 

      const save = function (e) {
        e.clipboardData.setData("text/plain", item.basic_phone);
        e.preventDefault(); // 阻止默认行为
      };
      document.addEventListener("copy", save); // 添加一个copy事件
      document.execCommand("copy"); // 执行copy方法
      this.$message({ message: "复制微信号成功", type: "success" });
    },


    ...mapActions({
      getResumeIsDown: "tool/getResumeIsDown",
    }),
    //下拉菜单
    commandHandle(command) {
      this.command = command;
    },
    getData() {
      let obj = { resume_id: this.id };
      getResumeDetails(obj).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.data = res.data;

          this.$nextTick(() => {
            this.loading = false;
          });
        }
      });
    },
    //下载简历
    down() {
      this.$confirm("是否获取该简历?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            resume_id: this.id,
          };
          downResume(data).then((res) => {
            if (res.code === 0) {
              this.getData();
              this.getResumeIsDown();
              this.$message({
                type: "success",
                message: res.msg,
              });
            } 
            else if(res.code === 666888){
                    // 666888  是种子，没有积分了  弹出在线支付对话框
                    this.centerDialogVisible = true
                    this.$emit("callBack",1);
            }else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  }, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.box {
  @include padding;
  min-width: 1100px;
  min-height: 70vh;
  padding-bottom: 20px;
}
.wrap {
  padding: 20px 50px;
  min-height: 80vh;
  // overflow: auto;
  // height: calc(90vh - 100px);
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: $radius;
  box-shadow: $box-shadow;
}
.get-btn-wrap {
  width: 100%;
  height: 50px;
}
.get-btn {
  float: right;
}

.get-btn::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

/* 头像 */
.img-wrap {
  width: 110px;
  height: 110px;
  float: left;
}
.img-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.all-base {
  float: left;
  margin-left: 120px;
}
.name {
  font-size: 28px;
  color: $jd_fontColor;
  margin-bottom: 10px;
}
.base {
  font-size: $jd_fontSize;
  color: $selfColor;
}
/* 基本信息间隔 */
i {
  margin: 0 15px;
  border-right: 1px solid #ccc;
}
// 标签

.tag span {
  width: 80px;
  height: 30px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 10px;
  text-align: center;
  line-height: 30px;
  background-color: $main_bc;
  font-size: $jd_fontSize;
  color: $main_color;
}
// 期望
.expect p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
}
.expect p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 20px;
}
.work,
.edu,
.self {
  margin-top: 39px;
}
.work {
  clear: both;
}
.company-name {
  font-size: 18px;
  padding-left: 10px !important;
  color: $jd_fontColor;
}
.work p {
  font-size: $jd_fontSize;
  color: $jd_fontColor;
  padding: 0 30px;
  margin: 10px 0 0 0;
}
.work span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.edu p {
  padding: 0 30px;
}
.edu p span {
  font-size: $jd_fontSize;
  color: $selfColor;
}
.self-word {
  font-size: $jd_fontSize;
  color: $selfColor;
  padding: 0px 30px;
}




.chongzhiC .payConent {
  overflow: hidden;
}
.chongzhiC div {
  color: #999;
  overflow: hidden;
}
.chongzhiC .payConent > div:first-child {
  width: calc(80% - 26px);
  border: 1px solid #f17a0a;
  padding: 0 12px 12px 12px;
  width: 424px;
}
.chongzhiC .payConent > div {
  float: left;
  margin-bottom: 12px;
}
.chongzhiC .payConent .payHeader > div:first-child {
  font-size: 18px;
  color: #2b2a2a;
  font-weight: 700;
  margin-bottom: 8px;
  padding: 6px 0;
  border-bottom: 1px solid #f17a0a;
}
.chongzhiC .payConent .payHeader > div:first-child span {
  font-family: Times New Roman, Times, serif;
  font-size: 30px;
  color: #000;
}
.chongzhiC div span {
  color: #1b1b1b;
}
.chongzhiC .payConent .payHeader > div:last-child {
  overflow: hidden;
  font-size: 12px;
}
.chongzhiC .payConent .payHeader > div:nth-child(2) {
  font-size: 14px !important;
  color: #f1600c;
  margin-bottom: 8px;
  border-bottom: 1px solid #f17a0a;
}
.chongzhiC .payConent .payHeader > div:nth-child(2) li {
  list-style: inside;
  margin: 4px 0;
}
::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}
.chongzhiC div .jfen {
  font-size: 20px;
  color: #f7410a;
}
.chongzhiC .fangshi > div {
  float: left;
}
.chongzhiC .fangshi > div:last-child {
  color: #111;
  border: 1px solid #86cc19;
  padding: 0 6px;
  border-radius: 4px;
  line-height: 32px;
  background: #e9f0df;
}
.chongzhiC .fangshi > div:last-child span {
  color: #86cc19;
  font-size: 20px;
  margin-right: 2px;
}
.chongzhiC div .netPay {
  font-size: 20px;
  color: #d30c0c;
}
.chongzhiC div .nowPay {
  float: right;
  padding: 8px 16px;
  background: #298deb;
  color: #fff;
  border-radius: 8px;
  margin-right: 20px;
  -webkit-box-shadow: #7bbaf5 0 0 7px 1px;
  box-shadow: 0 0 7px 1px #7bbaf5;
  cursor: pointer;
  margin-top: 20px;
}

.chongzhiC div .aa {
  position: relative;
  width: 250px;
  height: 250px;
}

.chongzhiC div .is_payImgBg {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  line-height: 250px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin: 0 0;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.qrcode {
  display: inline-block;
  img {
    width: 132px;
    height: 132px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
  }
}

</style>
